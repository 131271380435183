<template>
   <div class="card">
    <div class="card-body">
        <div class="media align-items-center">
            <div class="avatar-sm mr-3">
                <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">{{ group.name.charAt(0) }}</div>
            </div>
            <div class="media-body">
                <h5 class="my-1">
                    {{group.name}}
                </h5>
            </div>
        </div>
        <p class="my-1">
            {{group.description | truncate(100)}}
        </p>
        <p class="text-muted my-1">
            <i class="mdi mdi-flag-checkered mr-1"></i>
            <span>{{(group.countries.length>1)? "Countries": "Country"}}:</span> {{group.countries.map((i) => i.name).join(', ') | truncate(20)}}
        </p>
        <p class="text-muted mb-0">
            <i class="mdi mdi-flare mr-1"></i>
            <span>Region:</span> {{group.region | truncate(35)}}
        </p>
        <hr/>
        <div>
            <slot name="action"></slot>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        group:{
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>