<template>
<div>
    <div class="row mb-3">
        <div class="col-lg-4">
            <div class="mb-2 mb-lg-0">
                <router-link to="/languages" class="btn btn-primary" >
                    Add more languages
                </router-link>
            </div>
        </div>
        <div class="col-lg-8">
            <form class="form d-flex justify-content-end">
                <div class="mr-2 flex-grow-1">
                    <label class="d-block">
                        <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" 
                        placeholder="Search..." class="form-control"></b-form-input>
                    </label>
                </div>
                <div class="">
                    <b-dropdown variant="secondary" right>
                        <template slot="button-content">
                            Filter <i class="mdi mdi-filter-variant"></i>
                        </template>
                        <b-dropdown-item-button @click="sortBy = 'asc'"> Ascending </b-dropdown-item-button>
                        <b-dropdown-item-button @click="sortBy = 'desc'"> Descending </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </form>
        </div>
    </div>
    <div class="mb-2"><strong>Filters:</strong> Sort: <span class="text-uppercase">{{sortBy}}</span></div>
    <is-loading v-if="isLoading" />
    <div v-else>
        <div class="row">
            <div class="col-md-6" v-for="(group, index) in groups" :key="index">
                <group-card :group="group">
                    <template v-slot:action>
                        <div>
                            <router-link :to="`/languages/${group.id}`" class="btn-sm btn-primary">
                                Learn {{group.name}}
                            </router-link>
                        </div>
                    </template>
                </group-card>
            </div>

            <div v-if="!groups.length" class="col-12  text-center">
                <span v-if="searchQuery">No language matching your search: <strong>{{searchQuery}}</strong></span>
                <div v-else>
                    <h4 class="">Your language list is empty</h4>
                    <p class="mb-2">You can add one language to your list and learn for free</p>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-12 d-flex justify-content-center">
                <div class="pagination-rounded">
                    <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import GroupCard from '@/components/cards/GroupCard.vue';
import _ from 'lodash';

export default {
    components:{
        IsLoading,
        GroupCard
    },
    data() {
        return {
            isLoading: false,
            searchQuery: "",
            sortBy:'asc',
        };
    },
    watch: {
        sortBy (val) {
            this.fetchItems()
        }
    },
    computed: {
        pageData(){
            return this.$store.state.groupList.pageData
        },
        groups() {
            return this.pageData.data
        },
        currentPage: {
            get() {
                return this.pageData.current_page
            },
            set(val) {
                if(!this.pageData.current_page || this.pageData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.pageData.per_page || 10
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods: {
        updateSearchQuery: _.debounce(function(string) {
            this.fetchItems()
        }, 2000),
        fetchItems(page, per_page = null){
            let payload = {page: page || 1, sort_by: this.sortBy, 
            per_page: per_page || this.itemsPerPage, belong: 1 }
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("groupList/fetchGroups", payload)
            .then(response => this.isLoading = false)
        }
    },
    created() {
      this.fetchItems()
    },
};
</script>


